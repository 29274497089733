import { useEffect, useRef } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {LIC_KEY} from "../../App";

function configToJSON(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return {};
  }
}

export default function PdfViewerComponent(props) {
  const viewerRef = useRef(null);
  const fullScreenHandle = useFullScreenHandle();
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    let viewerInstance = null;

    const loadViewer = async () => {
      try {
        const WebViewerModule = await import("@pdftron/webviewer");
        const WebViewer = WebViewerModule.default;

        const { disableToolbarOptions, presentationMode } =
          configToJSON(window.configuration) || {};
        const fileName = props.document.split("/").pop() || "document.pdf";

        viewerInstance = await WebViewer(
          {
            path: 'https://tiiny.host/pdf-ui-dev/webviewer/lib',
            licenseKey: LIC_KEY,
            initialDoc: props.document,
            enableAnnotations: false,
            fullAPI: true,
          },
          container
        );

        viewerRef.current = viewerInstance;
        const { UI, Core } = viewerInstance;

        const theme = UI.Theme;
        UI.setTheme(theme.LIGHT);
        UI.disableElements(["menuButton", "annotationEditToolButton"]);

        const pageControls = new UI.Components.PageControls();
        const defaultHeader = new UI.getModularHeader("default-top-header");
        defaultHeader.setItems([...defaultHeader.items, pageControls]);

        if (!disableToolbarOptions?.includes("export-pdf")) {
          const downloadButton = new UI.Components.CustomButton({
            dataElement: "downloadButton",
            className: "custom-button-class",
            label: "Download",
            title: "Download PDF",
            onClick: async () => {
              const doc = await Core.documentViewer.getDocument().getFileData({
                xfdfString: await Core.annotationManager.exportAnnotations(),
              });
              const blob = new Blob([doc], { type: "application/pdf" });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = fileName;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            },
            img: "icon-save",
            style: {
              backgroundColor: presentationMode ? "#2D2D2D" : "#F1F3F5",
              color: presentationMode ? "#FFFFFF" : "#000000",
            },
          });

          const printButton = new UI.Components.CustomButton({
            dataElement: "printButton",
            className: "custom-button-class",
            label: "Print",
            title: "Print PDF",
            onClick: () => UI.print(),
            img: "icon-header-print-line",
            style: {
              backgroundColor: presentationMode ? "#2D2D2D" : "#F1F3F5",
              color: presentationMode ? "#FFFFFF" : "#000000",
            },
          });

          const defaultHeader = new UI.getModularHeader("default-top-header");
          defaultHeader.setItems([...defaultHeader.items, downloadButton, printButton]);
        }

        if (presentationMode) {
          UI.setTheme(theme.DARK);

          Core.documentViewer.addEventListener('documentLoaded', async () => {
            const displayModeManager = Core.documentViewer.getDisplayModeManager();
            const documentViewer = Core.documentViewer;
          
            const pageNumber = 1; // Check the first page
            const rotation = documentViewer.getCompleteRotation(pageNumber);
            const pageWidth = documentViewer.getPageWidth(pageNumber);
            const pageHeight = documentViewer.getPageHeight(pageNumber);
          
            let displayMode;
          
            if ((rotation === 0 || rotation === 180) && pageHeight > pageWidth) {
              // Portrait orientation
              displayMode = Core.DisplayModes.CoverFacing;
            } else if ((rotation === 90 || rotation === 270) || pageWidth > pageHeight) {
              // Landscape orientation
              displayMode = Core.DisplayModes.Single;
            }
          
            displayModeManager.setDisplayMode(new Core.VirtualDisplayMode(documentViewer, displayMode, false));
          });

          
          const defaultHeader = new UI.getModularHeader("default-top-header");
          const defaultHeaderItems = defaultHeader.items;

          const topHeader = new UI.Components.ModularHeader({
            dataElement: "default-top-header",
            placement: "bottom",
            grow: 0,
            gap: 12,
            position: "start",
            stroke: true,
            dimension: {
              paddingTop: 8,
              paddingBottom: 8,
              borderWidth: 1,
            },
            style: {},
            items: defaultHeaderItems,
          });

          UI.setModularHeaders([topHeader]);

          // Add Fullscreen Button in Presentation Mode
          const fullScreenButton = new UI.Components.CustomButton({
            dataElement: "fullScreenButton",
            className: "custom-button-class",
            label: "Fullscreen",
            title: "Enter Fullscreen",
            onClick: () => fullScreenHandle.enter(),
            img: "icon-fullscreen",
            style: {
              backgroundColor: "#2D2D2D",
              color: "#FFFFFF",
            },
          });

          defaultHeader.setItems([...defaultHeader.items, fullScreenButton]);
        }

        document.addEventListener("keydown", (event) => {
          const currentPage = Core.documentViewer.getCurrentPage();
          const totalPages = Core.documentViewer.getPageCount();
        
          const pageNumber = 1; 
          const rotation = Core.documentViewer.getCompleteRotation(pageNumber);
          const pageWidth = Core.documentViewer.getPageWidth(pageNumber);
          const pageHeight = Core.documentViewer.getPageHeight(pageNumber);
          
          const isPortrait = (rotation === 0 || rotation === 180) && pageHeight > pageWidth;
            
          const pageIncrement = isPortrait && presentationMode ? 2 : 1;
        
          if (event.key === "ArrowRight") {
            const nextPage = currentPage + pageIncrement;
            Core.documentViewer.setCurrentPage(nextPage > totalPages ? totalPages : nextPage);
          } else if (event.key === "ArrowLeft") {
            const prevPage = currentPage - pageIncrement;
            Core.documentViewer.setCurrentPage(prevPage < 1 ? 1 : prevPage);
          }
        });

      } catch (error) {
        console.error("Error initializing WebViewer:", error);
      }
    };

    loadViewer();

    return () => {
      if (viewerRef.current) {
        try {
          viewerRef.current.UI.dispose();
        } catch (e) {
          console.error("Error disposing WebViewer:", e);
        }
      }
    };
  }, [props.document]);

  return (
    <FullScreen handle={fullScreenHandle}>
      <div ref={containerRef} style={{ width: "100%", height: "100vh" }} />
    </FullScreen>
  );
}
