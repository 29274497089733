import React from "react";
import PdfViewerComponent from "./components/renderer/PdfViewer.js";
import ImageViewer from "./components/renderer/ImageViewer.js";
import ExcelViewer from "./components/renderer/ExcelViewer.js";
import {
  isImageFile,
  isExcelFile,
  getFileCategoryType,
} from "./utils/file.js";

import "./App.css";

export const LIC_KEY = "TIINY LABS LIMITED:OEM:Tiiny Host::B+:AMS(20260316):65CC2F62076A24F3BB313AC9A24338264E6F4F445E66670E40966918A7E92DD90931F5C7";

const ViewerComponent = () => {
  const fileExtension = window.fileName.split(".").pop().toLowerCase();

  const filename = window.fileName;
  const fileCategory = getFileCategoryType(filename);

  if (fileExtension === "pdf") {
    return (
      <div className="App-viewer">
        <PdfViewerComponent document={`${filename}`} />
      </div>
    );
  } else {
    switch (true) {
      case isImageFile(filename):
        return <ImageViewer filename={`${filename}.${fileCategory}`} />;
      case isExcelFile(filename):
        return <ExcelViewer filename={`${filename}.${fileCategory}`} />;
      default:
        return <div>Unsupported file type: {fileExtension}</div>;
    }
  }
};

function App() {
  return (
    <div className="App">
      <ViewerComponent />
    </div>
  );
}

export default App;
